'use client'

import {debounce} from 'lodash'
import {
  type ReadonlyURLSearchParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {tv} from 'tailwind-variants'
import {Button, grid} from '~/design-system/foundations'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '~/design-system/foundations/Dialog'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/design-system/foundations/Popover'
import {HgButton, HgFilter, HgSearchInput} from '../../components'
import {
  type HgFilterRangeTabState,
  type HgFilterTabPropsWithContent,
  type HgFilterTabStateRecord,
} from '../../components/HgFilter/types'
import {textLink} from '../../components/HgTextLink'
import {type HgDatabaseTableProps} from '../HgDatabaseTable/types'
import ExpandableTagGroup from './_components/ExpandableTagGroup'
import {formatFilterLabel} from './_utils/formatTagLabels'

export type FilterDisplayConfig = HgDatabaseTableProps['filterDisplayConfig']

export type HgFilterSearchProps = {
  filterTabs: HgFilterTabPropsWithContent[]
  filterDisplayConfig: FilterDisplayConfig
  isSticky?: boolean
  className?: string
  showSearch?: boolean
}

const HgFilterSearchVariants = tv({
  slots: {
    wrapper: 'w-full',
    gridStyles: 'mx-auto gap-y-s4 pt-s9 md:gap-y-s3',
    filterButtonWrapper:
      'order-2 col-span-full flex items-center gap-s4 md:order-1 md:col-span-8 md:gap-s3 lg:col-start-3',
    filterCountWrapper:
      'flex min-h-32 items-center gap-s3 border-l border-border-frosted pl-s3 arcadia-ui-1 lg:h-32',
    searchInput:
      'order-1 col-span-full md:order-2 md:col-span-4 md:col-start-9 lg:col-start-11',
    popoverContent: 'z-[5] hidden md:block md:h-[436px] md:w-full',
    dialogContent:
      'bg-background bg-background fixed inset-0 z-50 overflow-hidden p-0 shadow-none md:hidden',
  },
  variants: {
    isSticky: {
      true: {
        wrapper:
          // filter module has top padding of `s9`, so position needs to account for that
          'sticky top-[calc(var(--s3)-(var(--s9)-var(--navbar-height)))] z-[2] bg-background-default',
      },
    },
  },
})

function searchParamsUpdated({
  previous,
  current,
  excludedParams = [],
}: {
  previous: URLSearchParams
  current: URLSearchParams
  excludedParams?: string[]
}) {
  const previousEntries = Array.from(previous.entries()).filter(
    ([key]) => !excludedParams.includes(key)
  )

  const currentEntries = Array.from(current.entries()).filter(
    ([key]) => !excludedParams.includes(key)
  )

  if (previousEntries.length !== currentEntries.length) {
    return true
  }

  for (const [key, value] of currentEntries) {
    if (!previous.has(key) || previous.get(key) !== value) {
      return true
    }
  }
}

const FilterContent = ({
  tabs,
  closeMenu,
  onFilterChange,
  initialState,
}: {
  tabs: HgFilterTabPropsWithContent[]
  closeMenu: () => void
  onFilterChange: (filters: HgFilterTabStateRecord) => void
  initialState: HgFilterTabStateRecord
}) => {
  return (
    <HgFilter
      tabs={tabs}
      onFilterChange={onFilterChange}
      closeMenu={closeMenu}
      initialState={initialState}
    />
  )
}

const FilterPopover = ({
  tabs,
  handleFilterChange,
  initialFilterState,
  className,
}: {
  tabs: HgFilterTabPropsWithContent[]
  handleFilterChange: (filters: HgFilterTabStateRecord) => void
  initialFilterState: HgFilterTabStateRecord
  className?: string
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handlePopoverClick = () => {
    setIsPopoverOpen(true)
  }

  const handleCloseMenu = () => {
    setIsPopoverOpen(false)
  }

  const onFilterChange = (filters: HgFilterTabStateRecord) => {
    if (!isPopoverOpen) {
      return
    }

    handleFilterChange(filters)
  }

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <HgButton
          variant="frosted"
          iconProps={{iconType: 'filter', position: 'left'}}
          onClick={handlePopoverClick}
          className="hidden md:flex"
        >
          Add Filters
        </HgButton>
      </PopoverTrigger>
      <PopoverContent
        className={className}
        align="start"
        side="bottom"
        // avoid collision with the navbar
        collisionPadding={{top: 72}}
      >
        <FilterContent
          onFilterChange={onFilterChange}
          tabs={tabs}
          closeMenu={handleCloseMenu}
          initialState={initialFilterState}
        />
      </PopoverContent>
    </Popover>
  )
}

const FilterDialog = ({
  tabs,
  handleFilterChange,
  initialFilterState,
  className,
}: {
  tabs: HgFilterTabPropsWithContent[]
  handleFilterChange: (filters: HgFilterTabStateRecord) => void
  initialFilterState: HgFilterTabStateRecord
  className?: string
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDialogClick = () => {
    setIsDialogOpen(true)
  }

  const handleCloseMenu = () => {
    setIsDialogOpen(false)
  }

  const onFilterChange = (filters: HgFilterTabStateRecord) => {
    if (!isDialogOpen) {
      return
    }

    handleFilterChange(filters)
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <HgButton
          variant="frosted"
          iconProps={{iconType: 'filter', position: 'left'}}
          onClick={handleDialogClick}
          className="md:hidden"
        >
          Add Filters
        </HgButton>
      </DialogTrigger>
      <DialogTitle className="sr-only">Filter Menu</DialogTitle>
      <DialogDescription className="sr-only">
        Apply filters to the table, click close when done
      </DialogDescription>
      <DialogContent className={className}>
        <FilterContent
          initialState={initialFilterState}
          onFilterChange={onFilterChange}
          tabs={tabs}
          closeMenu={handleCloseMenu}
        />
      </DialogContent>
    </Dialog>
  )
}

export default function HgFilterSearch({
  filterTabs,
  filterDisplayConfig,
  isSticky,
  showSearch = true,
  className,
}: HgFilterSearchProps) {
  const {linkWrapper} = textLink({interaction: 'underline'})
  const searchParams = useSearchParams()
  const router = useRouter()
  const pathname = usePathname()
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '')

  const memoizedFilterTabs = useMemo(() => filterTabs, [filterTabs])

  // Initialize filter state from URL params
  const initializeFilterState = useCallback(
    (searchParams: ReadonlyURLSearchParams) => {
      const initialState: HgFilterTabStateRecord = {}
      memoizedFilterTabs.forEach(tab => {
        if (tab.type === 'checkbox' || tab.type === 'radio') {
          const paramValue = searchParams.get(tab.tabValue)
          if (paramValue) {
            initialState[tab.tabValue] =
              tab.type === 'radio' ? paramValue : paramValue.split(',')
          }
        } else if (tab.type === 'range') {
          const minValue = searchParams.get(`${tab.tabValue}-min`)
          const maxValue = searchParams.get(`${tab.tabValue}-max`)
          if (minValue || maxValue) {
            initialState[tab.tabValue] = {min: minValue || '', max: maxValue || ''}
          }
        }
      })
      return initialState
    },
    [memoizedFilterTabs]
  )

  const [filterState, setFilterState] = useState<HgFilterTabStateRecord>(
    initializeFilterState(searchParams)
  )

  // Update URL when filter state changes
  const updateURLParams = useCallback(() => {
    const newParams = new URLSearchParams(searchParams)

    // Remove existing filter params
    memoizedFilterTabs.forEach(tab => {
      newParams.delete(tab.tabValue)
      newParams.delete(`${tab.tabValue}-min`)
      newParams.delete(`${tab.tabValue}-max`)
    })

    // Add new filter params
    Object.entries(filterState).forEach(([key, value]) => {
      const tab = memoizedFilterTabs.find(t => t.tabValue === key)
      if (!tab) return

      switch (tab.type) {
        case 'checkbox':
          if (Array.isArray(value) && value.length > 0) {
            newParams.set(key, value.join(','))
          }
          break
        case 'radio':
          if (value) {
            newParams.set(key, value as string)
          }
          break
        case 'range': {
          const rangeValue = value as HgFilterRangeTabState
          if (rangeValue.min) newParams.set(`${key}-min`, rangeValue.min)
          if (rangeValue.max) newParams.set(`${key}-max`, rangeValue.max)
          break
        }
      }
    })

    // Update query param
    if (searchQuery) {
      newParams.set('query', searchQuery)
    } else {
      newParams.delete('query')
    }

    if (
      searchParamsUpdated({
        previous: searchParams,
        current: newParams,
        excludedParams: ['page'],
      })
    ) {
      newParams.delete('page')
    }

    if (new URLSearchParams(searchParams).toString() !== newParams.toString()) {
      router.push(`${pathname}?${newParams.toString()}`, {scroll: false})
    }
  }, [searchParams, memoizedFilterTabs, pathname, router, searchQuery, filterState])

  // Handle filter changes
  const handleFilterChange = useCallback((newState: HgFilterTabStateRecord) => {
    setFilterState(newState)
  }, [])

  // Handle search query changes
  useEffect(() => {
    const debouncedHandleQuery = debounce(() => {
      updateURLParams()
    }, 75)

    debouncedHandleQuery()

    return () => {
      debouncedHandleQuery.cancel()
    }
  }, [updateURLParams])

  const handleTagDismiss = useCallback(
    (tagId: string) => {
      const [key, value] = tagId.split('-')
      const newState = {...filterState}

      if (Array.isArray(newState[key])) {
        newState[key] = (newState[key] as string[]).filter(v => v !== value)
        if ((newState[key] as string[]).length === 0) {
          const {[key]: _, ...rest} = newState
          handleFilterChange(rest)
        } else {
          handleFilterChange(newState)
        }
      } else {
        const {[key]: _, ...rest} = newState
        handleFilterChange(rest)
      }
    },
    [filterState, handleFilterChange]
  )

  const filterTags = useMemo(() => {
    return Object.entries(filterState).flatMap(([key, value]) => {
      const tab = filterTabs.find(tab => tab.tabValue === key)
      if (!tab) return []

      if (Array.isArray(value)) {
        return value.map(v => ({
          id: `${key}-${v}`,
          body: (
            <p>
              {formatFilterLabel(key, v, tab.label as string, filterDisplayConfig)}
            </p>
          ),
          isDismissible: true,
          onDismiss: () => {
            handleTagDismiss(`${key}-${v}`)
          },
        }))
      } else if (typeof value === 'object' && value !== null) {
        return [
          {
            id: `${key}-range`,
            body: (
              <p>
                {formatFilterLabel(
                  key,
                  value as string,
                  tab.label as string,
                  filterDisplayConfig
                )}
              </p>
            ),
            isDismissible: true,
            onDismiss: () => {
              handleTagDismiss(`${key}-range`)
            },
          },
        ]
      } else if (value !== undefined) {
        return [
          {
            id: `${key}-${value}`,
            body: (
              <p>
                {formatFilterLabel(
                  key,
                  value,
                  tab.label as string,
                  filterDisplayConfig
                )}
              </p>
            ),
            isDismissible: true,
            onDismiss: () => {
              handleTagDismiss(`${key}-${value}`)
            },
          },
        ]
      }
      return []
    })
  }, [filterState, filterTabs, filterDisplayConfig, handleTagDismiss])

  const resetFilters = useCallback(() => {
    handleFilterChange({})
  }, [handleFilterChange])

  const filterCount = filterTags.length

  const {
    wrapper,
    gridStyles,
    filterButtonWrapper,
    filterCountWrapper,
    searchInput,
    popoverContent,
    dialogContent,
  } = HgFilterSearchVariants({isSticky})

  return (
    <div className={wrapper({class: className})}>
      <div className={grid({class: gridStyles()})}>
        <div className={filterButtonWrapper()}>
          <FilterPopover
            handleFilterChange={handleFilterChange}
            initialFilterState={filterState}
            tabs={memoizedFilterTabs}
            className={popoverContent()}
          />
          <FilterDialog
            handleFilterChange={handleFilterChange}
            initialFilterState={filterState}
            tabs={memoizedFilterTabs}
            className={dialogContent()}
          />
          <div className={filterCountWrapper()}>
            {filterCount > 0 ? (
              <Button onClick={resetFilters} className={linkWrapper()}>
                Reset filters ({filterCount})
              </Button>
            ) : (
              <span className="text-text-default">No filters applied</span>
            )}
          </div>
        </div>
        {showSearch && (
          <div className={searchInput()}>
            <HgSearchInput
              placeholder="Search"
              onChange={setSearchQuery}
              value={searchQuery}
            />
          </div>
        )}
        {Boolean(filterTags.length) && <ExpandableTagGroup tags={filterTags} />}
      </div>
    </div>
  )
}
